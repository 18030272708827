<template>
  <div>
    <b-alert
      v-model="showMessage"
      variant="success"
      dismissible
    >
      <div class="alert-body">
        <span>{{ message }}</span>
      </div>
    </b-alert>
    <b-card-actions
      ref="cardAction"
      title="Filters"
      @refresh="resetColFilters"
      @remove="resetColFilters"
    >
      <b-row>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <vc-date-picker
            v-model="start"
            mode="dateTime"
            :model-config="startModelConfig"
            is24hr
            @input="resetDay"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <label>From date</label>
              <input
                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 form-control"
                :value="inputValue"
                placeholder="From date"
                v-on="inputEvents"
              >
            </template>
          </vc-date-picker>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <vc-date-picker
            v-model="end"
            mode="dateTime"
            :model-config="endModelConfig"
            is24hr
            @input="resetDay"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <label>To date</label>
              <input
                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 form-control"
                :value="inputValue"
                placeholder="To date"
                v-on="inputEvents"
              >
            </template>
          </vc-date-picker>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <label>Day Select</label>
          <v-select
            v-model="dayFilter"
            :dir="
              $store.state.appConfig.isRTL
                ? 'rtl'
                : 'ltr'
            "
            :options="dayOptions"
            class="w-100"
            placeholder="Select day"
            :reduce="val => val.value"
            @input="restDate"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <label>Select Status</label>
          <v-select
            v-model="statusFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <label>Select Type</label>
          <v-select
            v-model="typeFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="typeOptions"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <label>Select Category</label>
          <v-select
            v-model="category"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="catOptions"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <label>Select Product</label>
          <v-select
            v-model="operator"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="operatorOption"
            class="w-100"
            placeholder="Select Product"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <label>Enter Order ID/Unique ID</label>
          <b-form-input
            id="orderid"
            v-model="orderid"
            placeholder="Order ID/Unique ID"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <label>Enter Transaction ID</label>
          <b-form-input
            id="lastserverid"
            v-model="lastserverid"
            placeholder="Transaction ID"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <label>Enter Reload No</label>
          <b-form-input
            id="lastserverid"
            v-model="reload_no"
            placeholder="Reload Number.."
          />
        </b-col>
        <b-col
          cols="4"
          md="1"
          class="mb-1 mt-2"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="handleSearch"
          >Search
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
        <b-col
          cols="4"
          md="2"
          class="mb-1 mt-2"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.report-email
            variant="primary"
            class="btn-icon"
          >
            Export
          </b-button>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h4>
          Transaction Report
        </h4>
        <p class="text-center">
          Total Transaction:
          <template v-if="!totalLoad">
            {{ transCount }}
          </template>
          <template v-else>
            <b-spinner
              small
              class="mr-1"
              label="Small Spinner"
            />
          </template>
        </p>
        <b-form-checkbox
          v-model="compact"
          class="text-right"
        >
          Small
        </b-form-checkbox>
      </b-card-header>
      <b-table-simple
        hover
        striped
        bordered
        responsive
        class="rounded-bottom mb-0"
        :small="compact"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th>Product/Type</b-th>
            <b-th>Reload No/Account No</b-th>
            <b-th>Amount</b-th>
            <b-th>Status</b-th>
            <b-th>Debit(-)</b-th>
            <b-th>Credit(+)</b-th>
            <b-th>Balance</b-th>
            <b-th>Reference No.</b-th>
            <b-th>API ID/Order ID</b-th>
            <b-th>Txn ID</b-th>
            <b-th>Date & Time</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-show="showLoading"
            class="b-table-busy-slot"
          >
            <b-td colspan="11">
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
              </div>
            </b-td>
          </b-tr>
          <template v-if="list.length > 0 && !showLoading">
            <b-tr
              v-for="(tr, indextr) in list"
              :key="indextr"
            >
              <b-td>
                {{ tr.maintype == "Recharge" || tr.maintype == "IssueEpin" ? tr.operator : "" }}
                {{ tr.maintype }}
              </b-td>
              <b-td>
                {{ tr.reloadno }}
              </b-td>

              <b-td>{{ tr.org_amount }}</b-td>
              <b-td>
                <b-badge
                  :variant="getOrderStatusColor(tr.status)"
                >
                  {{ tr.status }}
                </b-badge>
              </b-td>
              <b-td>
                <b-badge
                  v-if="tr.type == 'Debit'"
                  variant="light-danger"
                >
                  {{ tr.amount }}
                </b-badge>
              </b-td>
              <b-td>
                <b-badge
                  v-if="tr.type == 'Credit'"
                  variant="light-success"
                >
                  {{ tr.amount }}
                </b-badge>
              </b-td>
              <b-td>
                {{ tr.balance }}
              </b-td>
              <b-td>{{ tr.ref_no }}</b-td>
              <b-td v-if="activeUserInfo.loginid == 60386011888">
                {{ tr.api_txnid }}<br>{{ tr.otherfield }}
              </b-td>
              <b-td v-else>
                {{ tr.api_txnid }}<br>{{ tr.request_id_no }}
              </b-td>

              <b-td>{{ tr.serverid }}</b-td>
              <b-td style="padding: 10px;">{{
                tr.inserteddatetime
              }}</b-td>
            </b-tr>
          </template>
          <template v-else-if="!showLoading">
            <b-tr rowspan="10">
              <b-td
                colspan="11"
                class="text-center"
              >
                No data available
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
      <b-card-body>
        <vue-pagination
          v-if="list.length > 0"
          :pagination="userData"
          :offset="4"
          @paginate="handleChangePage"
        />
      </b-card-body>
    </b-card>

    <validation-observer
      ref="emailreport"
      tag="form"
    >
      <b-modal
        id="report-email"
        modal-class="modal-success"
        centered
        title="Report Send To Email"
      >
        <b-card-text>
          <label>Email</label>
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              id="email"
              v-model="other_email"
              placeholder="Enter Email"
              :class="errors.length > 0 ? 'is-invalid' : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-card-text>

        <template #modal-footer>
          <b-button
            size="sm"
            variant="success"
            @click="exportReport"
          >
            Send
          </b-button>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>
<script>
/* eslint-disable import/no-unresolved */
import { required, email } from '@validations'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTableSimple,
  BCard,
  BCardHeader,
  BCardBody,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BBadge,
  BModal,
  BCardText,
  BAlert,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import UserReportService from '../../services/user.report.service'
import OperatorService from '../../services/operator.service'
import JwtService from '../../common/jwt.service'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTableSimple,
    BCardText,
    BCard,
    BCardHeader,
    BCardBody,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    vSelect,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BButton,
    BBadge,
    BCardActions,
    BSpinner,
    BFormCheckbox,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dateFromFilter: 'To',
      dateToFilter: 'Form',
      compact: false,
      totalLoad: true,
      transCount: 0,
      date: '',
      orderid: '',
      required,
      email,
      showLoading: false,
      reload_no: '',
      operator: '',
      statusFilter: '',
      start: '',
      end: '',
      startModelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss', // Uses 'iso' if missing
        timeAdjust: '00:00:00',
      },
      endModelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss', // Uses 'iso' if missing
        timeAdjust: '23:59:00',
      },
      dayFilter: '',
      dayOptions: [
        { label: 'Today', value: 'today' },
        { label: 'Week', value: 'week' },
        { label: 'Month', value: 'month' },
      ],
      other_email: '',
      statusOptions: [
        { label: 'All', value: '' },
        { label: 'Transfered', value: 'Transfered' },
        { label: 'Success', value: 'Success' },
        { label: 'Refunded', value: 'Refunded' },
        { label: 'InProcess', value: 'InProcess' },
        { label: 'Failed', value: 'Failed' },
      ],
      category: '',
      catOptions: [
        { label: 'All', value: '' },
        { label: 'Debitnote', value: 'Debitnote' },
        { label: 'Balance', value: 'Balance' },
        { label: 'Recharge', value: 'Recharge' },
        { label: 'Registration', value: 'PRODUCTREGISTRATION' },
        { label: 'Bill Payment', value: 'Bill_Payment' },
        { label: 'E-pin [Uploaded]', value: 'Epin' },
        { label: 'IssueEpin', value: 'IssueEpin' },
        { label: 'Transfer', value: 'Transfer' },
        { label: 'Joining', value: 'Joining' },
        { label: 'Payout', value: 'Payout' },
      ],
      typeFilter: '',
      typeOptions: [
        { label: 'All', value: '' },
        { label: 'Credit', value: 'C' },
        { label: 'Debit', value: 'D' },
      ],
      operatorOption: [],
      status: 'All',
      showMessage: false,
      message: '',
      // products: [],
      itemsPerPage: 25,
      isMounted: false,
      totalItems: 0,
      activePrompt: false,
      userData: {},
      is_dark: false,
      lastserverid: '',
      transections: [],
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    list() {
      return this.transections
    },
    queriedItems() {
      return this.totalItems
    },
    activeUserInfo() {
      const user = JwtService.getUser()
      return user
    },
  },
  created() {
    this.getOperatorList()
  },
  mounted() {
    this.getTransectionInfo({ table: 'transactions_2021_laz' })
    this.totalCount()
    window.addEventListener('storage', this.changeMode)
  },
  methods: {
    changeMode() {
      if (localStorage.getItem('vuexy-skin') === 'dark') {
        this.is_dark = true
      } else {
        this.is_dark = false
      }
    },
    restDate() {
      this.end = ''
      this.start = ''
      this.date = ''
    },
    resetDay() {
      this.dayFilter = ''
    },
    getOrderStatusColor(status) {
      if (status === 'Success') return 'light-success'
      if (status === 'Transfered') return 'light-success'
      if (status === 'Refunded') return 'light-warning'
      if (status === 'InProcess') return 'light-warning'
      if (status === 'Failed') return 'light-danger'
      return 'light-success'
    },
    getOperatorList() {
      OperatorService.getAllOperater().then(
        response => {
          const self = this
          response.map(operator => self.operatorOption.push({
            value: operator.id,
            label: operator.operator_name,
          }))
        },
        () => {},
      )
    },
    handleSearch() {
      if ((this.start && this.end) || this.dayFilter !== '') {
        this.date = `${this.start}#${this.end}`
        if (this.start == null || this.start === '') {
          this.date = ''
        }
        let conditaion
        if (this.dayFilter !== '') {
          conditaion = true
        } else {
          conditaion = this.start < this.end
        }

        if (conditaion) {
          this.getTransectionInfo({
            status: this.statusFilter,
            type: this.typeFilter,
            date: this.date,
            mmtyp: this.category,
            opr: this.operator,
            table: 'transactions_2021_laz',
            orderid: this.orderid,
            reload_no: this.reload_no,
            day: this.dayFilter,
            lastserverid: this.lastserverid,
          })
          this.totalCount()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Please change start and end date',
              icon: 'MessageSquareIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please Select date or Day',
            icon: 'MessageSquareIcon',
            variant: 'danger',
          },
        })
      }
    },
    handleChangePage() {
      this.getTransectionInfo({
        page: this.userData.current_page,
        status: this.statusFilter,
        type: this.typeFilter,
        date: this.date,
        mmtyp: this.category,
        opr: this.operator,
        table: 'transactions_2021_laz',
        orderid: this.orderid,
        reload_no: this.reload_no,
        day: this.dayFilter,
        lastserverid: this.lastserverid,
      })
    },
    totalCount() {
      this.totalLoad = true
      UserReportService.getAllTransactionInfo({
        status: this.statusFilter,
        type: this.typeFilter,
        date: this.date,
        mmtyp: this.category,
        opr: this.operator,
        orderid: this.orderid,
        reload_no: this.reload_no,
        table: 'transactions_2021_laz',
        day: this.dayFilter,
        lastserverid: this.lastserverid,
        count: true,
      }).then(
        response => {
          this.transCount = response
          this.totalLoad = false
        },
        () => {},
      )
    },
    handleSort() {},
    resetColFilters() {
      this.statusFilter = 'All'
      this.typeFilter = 'All'
      this.date = ''
      this.start = ''
      this.end = ''
      this.orderid = ''
      this.reload_no = ''
      this.category = 'All'
      this.operator = ''
      this.lastserverid = ''
      this.getTransectionInfo()
      this.$refs.cardAction.showLoading = false
      this.totalCount()
    },
    exportReport() {
      if ((this.start && this.end) || this.dayFilter !== '') {
        this.date = `${this.start}#${this.end}`
        if (this.start == null || this.start === '') {
          this.date = ''
        }
        let conditaion
        if (this.dayFilter !== '') {
          conditaion = true
        } else {
          conditaion = this.start < this.end
        }

        if (conditaion) {
          this.$refs.emailreport.validate().then(success => {
            if (success) {
              UserReportService.exportAllTransactionInfo({
                status: this.statusFilter,
                type: this.typeFilter,
                date: this.date,
                mmtyp: this.category,
                opr: this.operator,
                orderid: this.orderid,
                table: 'transactions_2021_laz',
                reload_no: this.reload_no,
                email: this.other_email,
                day: this.dayFilter,
                lastserverid: this.lastserverid,
              }).then(
                response => {
                  this.showMessage = true
                  this.message = response.message
                  this.$bvModal.hide('report-email')
                },
                () => {},
              )
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Please change start and end date',
              icon: 'MessageSquareIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please Select date or Day',
            icon: 'MessageSquareIcon',
            variant: 'danger',
          },
        })
      }
    },
    getTransectionInfo(data) {
      this.showLoading = true
      UserReportService.getAllTransactionInfo(data).then(
        response => {
          this.transections = response.Transactioninfo
          this.userData = response
          this.showLoading = false
        },
        () => {},
      )
    },
  },
}
</script>
    <style lang="scss" scoped>
    @import "~@core/scss/base/bootstrap-extended/include";
    @import "~@core/scss/base/components/variables-dark";

    .card {
        ::v-deep .card-header {
            .heading-elements {
                position: static;
                cursor: inherit;

                .list-inline {
                    display: block;

                    li {
                        a {
                            padding: 0;
                        }

                        &:not(:last-child) {
                            margin-right: 1rem;
                        }
                    }
                }
            }
        }
    }

    .dark-layout {
        .b-overlay-wrap ::v-deep .b-overlay {
            // border: 10px solid red;
            .bg-white {
                background-color: $theme-dark-body-bg !important;
            }
        }
    }
    </style>
